define(["dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/97e6632a053d6f8dfd353c8fbbb1e26c2dd670cb/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (dojo) {
  // module:
  //		dijit/main

  /*=====
  return {
  	// summary:
  	//		The dijit package main module.
  	//		Deprecated.   Users should access individual modules (ex: dijit/registry) directly.
  };
  =====*/

  return dojo.dijit;
});