define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/97e6632a053d6f8dfd353c8fbbb1e26c2dd670cb/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare) {
  var ServiceUnavailableError = declare([Error], {
    constructor: function (message, code, errors) {
      this.message = message;
      this.code = code;
      this.errors = errors;
    }
  });
  ServiceUnavailableError.CODE_REQUESTMAPPER = 1526919;
  ServiceUnavailableError.createFromResponseData = function (responseData) {
    var message = responseData.message;
    var code = responseData.code;
    var error = new ServiceUnavailableError(message, code);
    return error;
  };
  return ServiceUnavailableError;
});