define(["dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/97e6632a053d6f8dfd353c8fbbb1e26c2dd670cb/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/config!/home/mcdeploy/mc_node_modules_cache/97e6632a053d6f8dfd353c8fbbb1e26c2dd670cb/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // defaultDuration
"dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/97e6632a053d6f8dfd353c8fbbb1e26c2dd670cb/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "../registry", "../main" // for setting exports to dijit namespace
], function (array, config, lang, registry, dijit) {
  // module:
  //		dijit/_base/manager

  var exports = {
    // summary:
    //		Deprecated.  Shim to methods on registry, plus a few other declarations.
    //		New code should access dijit/registry directly when possible.
  };
  array.forEach(["byId", "getUniqueId", "findWidgets", "_destroyAll", "byNode", "getEnclosingWidget"], function (name) {
    exports[name] = registry[name];
  });
  lang.mixin(exports, {
    // defaultDuration: Integer
    //		The default fx.animation speed (in ms) to use for all Dijit
    //		transitional fx.animations, unless otherwise specified
    //		on a per-instance basis. Defaults to 200, overrided by
    //		`djConfig.defaultDuration`
    defaultDuration: config["defaultDuration"] || 200
  });
  lang.mixin(dijit, exports);

  /*===== return exports; =====*/
  return dijit; // for back compat :-(
});