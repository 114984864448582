/**
 * AMD module for handling client-side GET requests on landing pages with callbacks.
 * Performs a simple check on the window location's protocol, host, and port
 * to determine whether XHR can be used. If so, fetch via XHR, otherwise, fetch via JSONP.
 *
 * The primary motivation for having this module is the existence of branded domain internal
 * proxy URLs (see LP-1444 for more context). Since
 * we're signing the URLs with an HMAC having the message:
 * `<branded domain> <internal resource URL>`,
 * it is crucial that the internal resource URLs not be modified by the client after we've
 * generated the HMAC. Currently, we use JSONP (which *does* perform client-side modification of URLs)
 * for all client-side requests on landing pages to get around the same-origin issue => JSONP-ed URLs
 * will fail HMAC validation. In order to resolve this, we can conditonally perform
 * the network call using XHR if the url's origin is the same as the window location origin (true
 * for all branded domain internal proxy URLs), which does not modify the URL in any way.
 *
 * @module mojo/page/request
 */
define(["dojo/request/script", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/97e6632a053d6f8dfd353c8fbbb1e26c2dd670cb/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (script, xhr) {
  return {
    /**
     * @param {string} url - The request URL
     * @param {(string|Object)=} query - The request query
     * @return {dojo/promise/Promise} - The result promise
     */
    get: function (url, query) {
      return this.canUseXHR(url) ? this._getViaXHR(url, query) : this._getViaJSONP(url, query);
    },
    /**
     * @param {string} url - The request URL
     * @param {(string|Object)=} query - The request query
     * @return {dojo/promise/Promise} - The result promise
     */
    _getViaXHR: function (url, query) {
      var params = {
        handleAs: "json"
      };
      if (query) {
        params["query"] = query;
      }
      return xhr.get(url, params);
    },
    /**
     * @param {string} url - The request URL
     * @param {(string|Object)=} query - The request query
     * @return {dojo/promise/Promise} - The result promise
     */
    _getViaJSONP: function (url, query) {
      var params = {
        jsonp: "c"
      };
      if (query) {
        params["query"] = query;
      }
      return script.get(url, params);
    },
    /**
     * @param {string} url - The request URL
     * @return {boolean} - True if we can use XHR to execute the request
     */
    canUseXHR: function (url) {
      var parsed = document.createElement("a");
      parsed.href = url;
      return window.location.protocol === parsed.protocol && window.location.host.toLowerCase() === parsed.host.toLowerCase() && window.location.port === parsed.port;
    }
  };
});